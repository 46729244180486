import Modal from "react-bootstrap/Modal";
import {BsXLg} from "react-icons/bs";
import classNames from "classnames";
import styles from "../../containers/Match/Match.module.scss";
import {Link} from "react-router-dom";
import {formatNumberToKWithoutRounding, toolTipProvider,} from "../../helpers/CommonFunctions";
import Paginator from "../Paginator/Paginator";
import React, {useEffect, useReducer, useState} from "react";
import {getCorrectWrongIconByHistoryStatus, getDisplayName, getPosition, getProfileImage, getUpDownSameIconByDirection} from "../../helpers/LeaderBoardHelpers";
import * as LeaderBoardService from "../../services/LeaderBoardService";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import OmniPoints from "../../assets/images/icons/24_xp.webp";

const LeaderBoardModal = ({
                             isShow,
                             setShow,
                             initialUsers,
                             totalElements,
                             currentUser,
                             location,
                             tournamentIdByProps,
                             authToken
                          }, props) => {

   const initialState = {
      isGettingLeaderBoardUsers: false,
      modalLBUsers: [],
      currentPage: 0,
   }

   const leaderBoardModalReducer = (state, action) => {
      switch (action.type) {
         case 'IS_GETTING_MODAL_USERS':
            return {...state, isGettingLeaderBoardUsers: action.payload};
         case 'MODAL_LB_USERS':
            return {...state, modalLBUsers: action.payload};
         case 'CURRENT_PAGE':
            return {...state, currentPage: action.payload};
      }
   }

   const [state, dispatch] = useReducer(leaderBoardModalReducer, initialState);
   const postsPerPage = 4;

   useEffect(() => {
      const tournamentId = tournamentIdByProps;
      const getPaginatedLeaderBoardData = () => {
         if (state.currentPage !== 0 && tournamentId) {
            LeaderBoardService.getTournamentLeaderBoard(state.currentPage, postsPerPage, tournamentId, 'rank', true, authToken).then((res) => {
               if (res?.apiStatus) {
                  dispatch({type: 'MODAL_LB_USERS', payload: res?.results});
               }
            });
         } else {
            dispatch({type: 'MODAL_LB_USERS', payload: initialUsers});
         }
      }
      getPaginatedLeaderBoardData();
   }, [state.currentPage, initialUsers]);

   const isPresentCurrentUserInLeaderBoardUsers = () => {
      if (!state.modalLBUsers)
         return true;
      return state.modalLBUsers?.find((leader) => leader?.userName === currentUser?.userName);
   }

   const paginateHandler = (pageNumber) => {
      dispatch({type: 'CURRENT_PAGE', payload: pageNumber});
   };

   const modalCloseHandler = () => {
      setShow(false);
      dispatch({type: 'CURRENT_PAGE', payload: 0});
   }

   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   const avgRightAnsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.AVERAGE_RIGHT_ANSWERS"), "top")
   const numOfMatchesAnsToolTip = toolTipProvider.bind(this, t("LEADER_BOARD_ROW.NUMBER_OF_MATCHES_ANSWERED"), "top")

   const currentUserScoreTableRowInModal = (
      <tr className={classNames(styles.leaderBoardHigh)}>
         {!translationsLoaded ? <></> : <>
            <td style={{verticalAlign: "top"}}>{getPosition(currentUser?.position, "modalCurrUsr")}</td>
            <td className="text-truncate ps-0 pe-0" style={{width: 'auto', verticalAlign: "top"}}>
               <div className="row p-0">
                  <div className="row p-0">
                     <div className="col-12 p-0 row" style={{lineHeight: "30px"}}>
                        <div className="col-12 col-md-6 p-0 float-left">
                           <div className="float-start col-10 p-0 text-truncate flex-row" style={{display: 'flex'}}>
                              {getProfileImage(currentUser?.profilePicUrl, currentUser?.firstName, currentUser?.lastName, false, currentUser?.userName)}
                              {getDisplayName(currentUser?.firstName, currentUser?.lastName, currentUser?.userName, 18)}
                           </div>
                        </div>
                        <div className="col-12 col-md-6 p-0 mt-2 mt-md-0 float-left">
                           {currentUser?.history?.slice(0, 5).reverse().map((hisObj, i) => getCorrectWrongIconByHistoryStatus(hisObj?.status, i))}
                        </div>
                     </div>
                  </div>
               </div>
            </td>
            <td style={{verticalAlign: "top"}}>
               <div className="col-12 p-0">
                  {numOfMatchesAnsToolTip(<label className="CorrectPresent"
                                                 style={{width: "49%"}}>
                     <>{currentUser?.matches || 0} <span
                        className={classNames(styles.tableText)}>(MTS.)</span></>
                  </label>, true)}
               </div>
            </td>
            <td style={{verticalAlign: "bottom"}}>
               <div className="row p-0 mb-2 pb-1 pb-md-0">
                  <div className="col-2 float-start p-0">
                     <img src={OmniPoints} alt={""}/>
                  </div>
                  <div className="float-right col-9 p-0 text-end text-bold">
                     {toolTipProvider(currentUser?.points, 'top', <span>&nbsp;&nbsp;{formatNumberToKWithoutRounding(currentUser?.points)}</span>, currentUser?.points > 999)}
                     &nbsp;&nbsp;{getUpDownSameIconByDirection(currentUser?.direction)}</div>
               </div>
            </td>
         </>}
      </tr>);

   return (<Modal show={isShow} size="lg">
      {!translationsLoaded ? <></> : <>
         <Modal.Body className="ps-1 ps-md-4 pe-1 pe-md-4">
            <a className="closeModal" style={{cursor: 'pointer'}}>
               <BsXLg onClick={modalCloseHandler}/>
            </a>
            <h1 className="leadTitile">{t("LEADER_BOARD_MODAL.LEADERBOARD")}</h1>
            <div className={classNames(styles.questWrap, "col-12", "mb-0 p-0 border-0")}>

               <table border="0" cellpadding="0" cellspacing="0" className={classNames(styles.leaderBoard)}>
                  {console.log("--------------", state?.modalLBUsers)}
                  {state?.modalLBUsers?.map((leader) => {
                     return (
                        <tr className={leader?.userName === currentUser?.userName && classNames(styles.leaderBoardHigh)} key={leader?.userName}>
                           <td style={{verticalAlign: "top"}}>{getPosition(leader?.position, "modalTypUsr")}</td>
                           <td className="text-truncate ps-0 pe-0" style={{width: 'auto', verticalAlign: "top"}}>
                              <div className="row p-0">
                                 <div className="row p-0">
                                    <div className="col-12 p-0 row" style={{lineHeight: "30px"}}>
                                       <div className="col-12 col-md-6 p-0 float-left"><Link
                                          to={leader.isPrivate ? '' : {
                                             pathname: `/user/${btoa(leader.userName)}`,
                                             search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                          }}>
                                          <div className="float-start col-10 p-0 text-truncate flex-row" style={{display: 'flex'}}>
                                             {getProfileImage(leader?.profilePicUrl, leader?.firstName, leader?.lastName, false, leader?.userName)}
                                             {getDisplayName(leader?.firstName, leader?.lastName, leader?.userName, 18)}
                                          </div>
                                       </Link>
                                       </div>
                                       <div className="col-12 col-md-6 p-0 mt-2 mt-md-0 float-left">
                                          {leader?.history?.slice(0, 5).reverse().map((hisObj, i) => getCorrectWrongIconByHistoryStatus(hisObj?.status, i))}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </td>
                           <td style={{verticalAlign: "top"}}>
                              <div className="col-12 p-0">
                                 {numOfMatchesAnsToolTip(<label className="CorrectPresent"
                                                                style={{width: "49%"}}>
                                    <>{currentUser?.matches || 0} <span
                                       className={classNames(styles.tableText)}>(MTS.)</span></>
                                 </label>, true)}
                              </div>
                           </td>
                           <td style={{verticalAlign: "bottom"}}>
                              <div className="row p-0 mb-2 pb-1 pb-md-0 ">
                                 <div className="col-2 float-start p-0">
                                    <img src={OmniPoints} alt={""}/>
                                 </div>
                                 <div className="float-right col-10 p-0 text-end greenText blackTextStyle">
                                    {toolTipProvider(leader?.points, 'top', <span>&nbsp;&nbsp;{formatNumberToKWithoutRounding(leader?.points)}</span>, leader?.points > 999)}
                                    &nbsp;&nbsp;{getUpDownSameIconByDirection(leader?.direction)}</div>
                              </div>
                           </td>
                        </tr>)
                  })}
               </table>
            </div>
            <table border="0" cellPadding="0" cellSpacing="0" className={classNames(styles.leaderBoard, "mt-3")}>
               {currentUser ? <>
                  {!isPresentCurrentUserInLeaderBoardUsers() ? currentUserScoreTableRowInModal : <></>}</> : <></>}
            </table>
            <div className="mt-3 mb-3 text-start">
               <Paginator postsPerPage={postsPerPage} totalElements={totalElements}
                          currentPage={state.currentPage}
                          paginate={paginateHandler}/>
            </div>
            <div className="w-100 text-end mt-2">
               <button className={classNames(styles.BlackSkBtn)} style={{maxWidth: "150px"}}
                       onClick={modalCloseHandler}>
                  <text>{t("LEADER_BOARD_MODAL.CLOSE")}</text>
               </button>
            </div>
         </Modal.Body>
      </>}
   </Modal>);
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(LeaderBoardModal);

import React, {useState} from "react";
import Classnames from "classnames";
import modalStyles from "./HomePageGuidancePopUp.module.scss";
import {useTranslation} from "react-i18next";

const HomePageGuidancePopUp = ({closeModal, instructionStep}) => {
   const {t} = useTranslation();
   const [lang, setLang] = useState(localStorage.getItem("language"));
   const videoUrls = {
      en: {
         step1: "https://storage.googleapis.com/meta11/serviceplatform/videos/HomePageStep01En.mp4",
         step2: "https://storage.googleapis.com/meta11/serviceplatform/videos/HomePageStep02En.mp4",
         step3: "https://storage.googleapis.com/meta11/serviceplatform/videos/HomePageStep03En.mp4",
         step4: "https://storage.googleapis.com/meta11/serviceplatform/videos/HomePageStep04En.mp4",
      },
      hi: {
         step1: "https://storage.googleapis.com/meta11/serviceplatform/videos/HomePageStep01Hi.mp4",
         step2: "https://storage.googleapis.com/meta11/serviceplatform/videos/HomePageStep02Hi.mp4",
         step3: "https://storage.googleapis.com/meta11/serviceplatform/videos/HomePageStep03Hi.mp4",
         step4: "https://storage.googleapis.com/meta11/serviceplatform/videos/HomePageStep04Hi.mp4",
      },
   };
   const Titels = {
      en: {
         step1: "SIGN UP",
         step2: "JOIN A TOURNAMENT",
         step3: "ENGAGE",
         step4: "$SPRTS TOKENS",
      },
      hi: {
         step1: "साइन अप करें",
         step2: "एक टूर्नामेंट में शामिल हों",
         step3: "काम पर लगाना",
         step4: "$स्पोर्ट्स टोकन",
      },
   };

   const videoUrl = videoUrls[lang] && videoUrls[lang][instructionStep] ? videoUrls[lang][instructionStep] : videoUrls['en'][1];
   const videoTitel = Titels[lang] && Titels[lang][instructionStep] ? Titels[lang][instructionStep] : Titels['en'][1];

   return (
      <div className={Classnames("modal fade show ScheduledMatchPopUp", modalStyles.modalBlur, modalStyles.PaymentConfirmmodal, modalStyles.HowDoesBotWorkMmodal, modalStyles.ScheduledMatchPopUp)} id="exampleModalCenter"
           tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
           style={{display: "block"}}>
         <div className={Classnames(modalStyles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
            <div className={Classnames("modal-content", modalStyles.modalContentContainer)}>
               <button className={Classnames(modalStyles.closeButton, "close z-3")} type="button" data-dismiss="modal"
                       aria-label="Close" onClick={closeModal}>
                   <span className={modalStyles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
               </button>
               <div className={Classnames(modalStyles.UserProfileModalWrapper)}>
                  <div className={Classnames(modalStyles.ellipse, modalStyles.eMulticolorHalf)}></div>
                  <div className={Classnames(modalStyles.ellipse, modalStyles.e392)}></div>
                  <div className="row p-0">
                     <div className={Classnames(modalStyles.UserProfileModal, "col-md-12")}>
                        <div className={Classnames(modalStyles.TitleWrapperm)}>
                           <h4>{videoTitel}</h4>
                        </div>
                     </div>
                  </div>
                  <div className="row p-0">
                     <div className={Classnames(modalStyles.ModalBody, "col-md-12 ps-4 pe-4 text-center")}>
                        <div className={Classnames(modalStyles.VideoRapper)}>
                           <video controls autoPlay playsInline muted loop style={{maxWidth: "100%"}}>
                              <source
                                 src={videoUrl}
                                 type="video/mp4"/>
                           </video>
                        </div>
                     </div>
                  </div>
                  <div className="row p-0">

                     <div className={Classnames(modalStyles.ellipse, modalStyles.redManEllipsRed)}></div>
                     <div className={Classnames(modalStyles.ellipse, modalStyles.eMulticolorFull)}></div>
                  </div>
                  <div className={Classnames(modalStyles.BtnRow, "row p-0")}>
                     <button type="button" data-dismiss="modal" aria-label="Close" onClick={closeModal} className="GrayButton">{t("FANTASY.CLOSE")}</button>
                  </div>
               </div>
            </div>
         </div>
      </div>)
}

export default HomePageGuidancePopUp;

import React, {useEffect, useState} from 'react';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import * as LeaderBoardService from "../../services/LeaderBoardService";
import {connect} from "react-redux";
import classNames from "classnames";
import Styles from "./LeaderBoardLIneChart.module.scss";
import {getConf} from "../../services/ConfigService";
import {LEADERBOARD_PAGE} from "../../utilities/ConfigConstants";
import {useParams} from "react-router-dom";

const LeaderBoardLineChart = (props) => {
   const [chartData, setChartData] = useState([]);
   const [maxPosition, setMaxPosition] = useState(3);
   const [loading, setLoading] = useState(false);
   let {tournamentId} = useParams();
   tournamentId = tournamentId || props?.tournamentDetails?.id;

   useEffect(() => {
      setLoading(true);
      LeaderBoardService.getPositionChartDetails(props?.userName, tournamentId, props?.token)
         .then((res) => {
            if (res?.apiStatus) {

               const formattedData = res?.records.map(record => ({
                  matchId: record.matchId,
                  position: record.position
               }));

               setChartData(formattedData);

               const calculatedMaxPosition = Math.max(...res.records.map(record => record.position));
               setMaxPosition(Math.max(calculatedMaxPosition));
            }
         })
         .catch(error => {
            console.error('Error fetching chart data:', error);
         });
      setLoading(false);
   }, [props?.userName, props?.token]);

   const generateTicks = (max) => {
      const ticks = [];
      for (let i = 1; i <= max; i++) {
         ticks.push(i);
      }
      return ticks;
   };
   const loadingIndicator = (<div style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   return (
      <>
         {props?.userName && getConf(LEADERBOARD_PAGE.LINE_CHART_LEADERBOARD) ?
            <div className={classNames(Styles.TournamentFantasySection)}>
               <div className={classNames(Styles.TournamentFantasySectionRow1, "row")}>
                  <div className="col">
                     <h2>
                        <span>Line Chart &nbsp;</span>
                     </h2>
                  </div>
               </div>
               <div className="row p-0">
                  <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
                     <div className={classNames(Styles.WinPrizes)}>
                        <div className={classNames(Styles.Conner1)}></div>
                        <div className={classNames(Styles.Conner2)}></div>
                        <div className={classNames(Styles.Conner3)}></div>
                        <div className={classNames(Styles.Conner4)}></div>

                        <div className={classNames(Styles.Shade1)}></div>
                        <div className={classNames(Styles.Shade2)}></div>
                        <div className={classNames(Styles.Shade3)}></div>
                        <div className={classNames(Styles.Shade4)}></div>

                        <div className={classNames(Styles.WinPrizesWrapper)}>
                           <div className={classNames(Styles.Tournament)}>
                              <div className={classNames(Styles.NoDataWrapper)}>
                                 <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#817e7e'}}>
                                    <div style={{width: '400px', height: '300px', backgroundColor: 'white', padding: '10px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
                                       {chartData.length > 0 && !loading ? (
                                          <ResponsiveContainer width="100%" height="100%">
                                             <LineChart data={chartData}>
                                                <CartesianGrid strokeDasharray="3 3"/>
                                                <XAxis
                                                   dataKey="matchId"
                                                   label={{value: 'Match ID', position: 'insideBottom', offset: -5}}
                                                />
                                                <YAxis
                                                   domain={[1, maxPosition + 1]}
                                                   ticks={generateTicks(maxPosition)}
                                                   reversed={true}
                                                   label={{value: 'Position', angle: -90, position: 'insideLeft'}}
                                                />
                                                <Tooltip/>
                                                <Line type="monotone" dataKey="position" stroke="#8884d8" activeDot={{r: 8}}/>
                                             </LineChart>
                                          </ResponsiveContainer>
                                       ) : (
                                          loadingIndicator
                                       )}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            : <></>}
      </>
   );
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(LeaderBoardLineChart);

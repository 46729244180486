import classnames from "classnames";
import classNames from "classnames";
import styles from "./FantasyBotHistory.module.scss";
import React, {useEffect, useRef, useState} from "react";

import SprtsToken from "../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../assets/images/icons/24_xp.webp";
import {useLocation} from "react-router-dom";
import {getUserFantasyTournamentHistory} from "../../services/FantasyService";
import {connect} from "react-redux";
import Paginator from "../Paginator/Paginator";
import {getConf} from "../../services/ConfigService";
import {WALLET} from "../../utilities/ConfigConstants";
import {t} from "i18next";
import {formatDate, formatNumberToKWithoutRounding, formatTime, getShowingFantasyPoints, getSprtsValueFromPoints} from "../../helpers/CommonFunctions";
import MoreInfoIcon from "../../assets/images/icons/more_info_icon.svg";
import Styles from "../Tournament/FantasyTeamSection/TournamentFantasySection.module.scss";
import PlayerBoostHistoryModal from "../Modals/PlayerBoostHistory/PlayerBoostHistory";
// import FlashIcon from "../../assets/images/icons/flash.svg";
import {ReactComponent as FlashIcon} from "../../assets/images/icons/flash.svg";
import PlayerInfoHistoryModal from "../Modals/PlayerInfoHistory/PlayerInfoHistory";

const FantasyBotHistory = (props) => {
   const fantasyHistoryTitleRef = useRef(null);
   const location = useLocation();
   const XP_TO_SPRTS_RATIO = getConf(WALLET.XP_TO_SPRTS_RATIO) || 1;

   const [isLoading, setIsLoading] = useState(true);
   const [tournamentTeamHistory, setTournamentTeamHistory] = useState([]);
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setPostsPerPage] = useState(3);
   const [totalElements, setTotalElements] = useState(0);
   const [totalPages, setTotalPages] = useState(0);

   const [showPlayerInfoHistoryModal, setShowPlayerInfoHistoryModal] = useState(false);
   const [showPlayerBoostHistoryModal, setShowPlayerBoostHistoryModal] = useState(false);
   const [selectedPlayerId, setSelectedPlayerId] = useState(null); // player id for match history modal
   const [selectedPlayerImage, setSelectedPlayerImage] = useState(null); // player image for match history modal
   const [selectedPlayerName, setSelectedPlayerName] = useState(null); // player name for match history modal
   const [selectedTournamentId, setSelectedTournamentId] = useState(null); // tournament id for match history modal

   const [modalData, setModalData] = useState(null);

   const paginate = (pageNumber, postsPerPage) => {
      setIsLoading(true);
      setPostsPerPage(Number(postsPerPage));
      setCurrentPage(pageNumber);
   };

   useEffect(() => {
      if (location.hash === "#fantasyHistoryTitleRef" && fantasyHistoryTitleRef.current) {
         const timer = setTimeout(() => {
            fantasyHistoryTitleRef.current.scrollIntoView({behavior: 'smooth'});
         }, 500);

         return () => {
            clearTimeout(timer);
         };
      }
   }, [location.hash]);

   useEffect(() => {
      const fetchFantasyTournamentHistory = () => {
         getUserFantasyTournamentHistory(props.userName, "tournament_id,desc", postsPerPage, currentPage, props.Token)
            .then(response => {
               if (response?.apiStatus && response?.teams) {
                  setTournamentTeamHistory(response.teams);
                  setTotalElements(response?.totalElements);
                  setTotalPages(response?.totalPages);
               }
            })
            .catch(error => {
               console.error("FantasyBotHistory -> fetchFantasyTournamentHistory -> error", error);
            })
            .finally(() => {
               setIsLoading(false);
            });
      }

      fetchFantasyTournamentHistory();
   }, [currentPage, postsPerPage, props.Token, props.userName]);

   const loadingIndicator = (<div className="w-100 text-center">
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   const renderPlayers = (tournament) => {
      let captain = null;
      let viceCaptain = null;
      let otherPlayers = [];

      tournament?.team.forEach((player) => {
         if (player.isCaptain) {
            captain = player;
         } else if (player.isVcCaptain) {
            viceCaptain = player;
         } else {
            otherPlayers.push(player);
         }
      });

      const onInfoHistoryClick = (playerId, playerImage, playerName, tournamentId) => {
         setSelectedPlayerId(playerId);
         setSelectedPlayerImage(playerImage);
         setSelectedPlayerName(playerName);
         setSelectedTournamentId(tournamentId);
         setShowPlayerInfoHistoryModal(true);
      }

      const onBoostIconClick = (player, tournament) => {
         setModalData({
            playerId: player?.playerId,
            playerName: player?.playerName,
            tournamentId: tournament?.tournamentId,
            tournamentName: tournament?.tournamentName,
         });
         setShowPlayerBoostHistoryModal(true);
      }

      const returnPillStatusClass = (pointSign) => {
         if (pointSign == '+') {
            return styles.PositivePill;
         } else if (pointSign == '-') {
            return styles.NagativePill;
         } else {
            return styles.ZeroPill;
         }
      }

      return [captain, viceCaptain, ...otherPlayers].map((player) => {
         if (!player) return null;
         return <div className={classnames(styles.Card)}>
            <div className={classnames(styles.CardBackground)}>
               <div className="row">
                  <a className={classnames(styles.MoreInfoIconBtn, "z-1")} style={{cursor: 'pointer'}}
                     onClick={() => onInfoHistoryClick(player?.playerId, player?.playerPictureURL, player?.playerName, tournament?.tournamentId)}><img
                     src={MoreInfoIcon}/></a>
                  <div className={classnames(styles.ProfilePic, styles.EmptyProfilePic)}
                       style={{backgroundImage: `url(${player?.playerPictureURL})`}}>

                  </div>
                  {!player?.isCaptain && !player?.isVcCaptain &&
                     <div className={classnames(styles.BoostIconWrapper, "BoostIconWrapper")}
                          onClick={() => onBoostIconClick(player, tournament)}>
                        <div className={classnames(styles.Circle, "Circle")}>
                           <div className={classnames(styles.InnerCircle, "InnerCircle")}></div>
                           {/* <img src={FlashIcon}/> */}
                           <FlashIcon/>
                        </div>
                     </div>
                  }
               </div>
               <div className={classnames(styles.TitleRow)}>
                  <h4>{player?.playerName}</h4>
                  {player?.isCaptain ? <span> (C)</span> : player?.isVcCaptain ?
                     <span> (VC)</span> : <span></span>}
               </div>
               <div className="row">
                  {(() => {
                     const playerPoints = getShowingFantasyPoints(player?.points);
                     return (
                        <div className={classnames(styles.PlayerPoints, "PlayerPoints", `${
                           returnPillStatusClass(playerPoints?.sign)
                        }`)}>
                           <span className={classNames(Styles.PointSplus, "PointSplus")}>{playerPoints.sign}</span>
                           <div className={classNames(Styles.PointsWrapper, "PointsWrapper", playerPoints.intPart === 0 ? 'noPoints' : '')}>
                              {/*<span className={classNames(Styles.Points, "Points")}>{playerPoints.intPart}{playerPoints.decPart}</span>*/}
                              <span className={classNames(Styles.DecimalPoints, "DecimalPoints")}>{playerPoints.intPart}{playerPoints.decPart}</span>
                           </div>
                        </div>
                     );
                  })()}
               </div>
            </div>
         </div>
      });
   }

   return (
      <div className={classnames(styles.botHistory, styles.FantasyBotHistory, "col-12 mb-5 row p-0")}>
         <div className="col-12 col-lg-12 p-0 row pe-0">
            <div id="fantasyHistoryTitleRef" ref={fantasyHistoryTitleRef}
                 className={classnames(styles.qBotHistoryTitle, "mt-5", "mb-3 p-0", "float-start")}>
               {t("FANTASY.FANTASY_TEAMS")}
            </div>

            {props?.encodedUserName === props?.userName ?
               <>
                  <div className={classnames(styles.historyWrapperContainer, "row gx-0 pb-4")}>
                     <div
                        className={classnames(styles.participatedMatchesWrapperG, "col pt-4")}
                        style={{textAlign: "center"}}>

                        <h2
                           className="heading4 w-100 upper-case">{t("FANTASY.HOW_YOUR_FANTASY_TEAM_SCORED_IN_TOURNAMENTS")}</h2>
                     </div>
                  </div>

                  {(!isLoading && tournamentTeamHistory.length > 0) ? tournamentTeamHistory.map((tournament, index) => {
                     const tournamentName = tournament?.tournamentName ? tournament?.tournamentName?.split(" ").slice(0, 2).join(" ") : "--";

                     return (
                        <div key={tournament?.tournamentId} className={classnames(styles.FantasyTeamContainer, "row")}>
                           <div className="row">
                              <div className="col-12">
                                 <div className={classnames(styles.FantasyInfoRow, "row")}>
                                    <div className={classnames(styles.MatchInfo, "col-12")}>
                                       {tournamentName + ' / '}
                                       {formatDate(tournament?.startTime)} &nbsp;/&nbsp; {formatTime(tournament?.startTime)}
                                    </div>
                                    <div className={classnames(styles.TeamName, "col-12")}>
                                       <h4>{tournament?.teamName}</h4>
                                    </div>
                                 </div>
                              </div>
                              <div className={classnames(styles.FantasyTeamWrapper, "col")}>
                                 {renderPlayers(tournament)}
                              </div>
                           </div>
                           <div className={classnames(styles.TotalEarningRow, "row")}>
                              <div className="col-md-12">
                                 <div className={classnames(styles.TotalEarningWrapper)}>
                                    <div className={classnames(styles.TotalEarning)}>
                                       <div className={classnames(styles.Title)}>
                                          {t("FANTASY.TOTAL_EARNING")}:
                                       </div>
                                       <div className={classnames(styles.SprtsToken)}>
                                          <img
                                             src={SprtsToken}/> {tournament?.totalPoints ? getSprtsValueFromPoints(tournament?.totalPoints, XP_TO_SPRTS_RATIO) : 0}
                                       </div>
                                       <div className={classnames(styles.OmniPoints)}>
                                          <img src={OmniPoints}/>{tournament?.totalPoints ? formatNumberToKWithoutRounding(tournament?.totalPoints) : 0}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>)
                  }) : isLoading ? loadingIndicator : <div
                     className={classnames(styles.NoTounamnetsToDisplayMsg)}>{t("FANTASY.NO_TOURNAMENT_HISTORY_TO_DISPLAY")}</div>}


                  {totalElements > 0 && <div className={classnames(styles.PaginatorRow, "row")}>
                     <Paginator postsPerPage={postsPerPage} totalElements={totalElements} currentPage={currentPage}
                                paginate={paginate}/>
                  </div>}
               </> :
               <div className={classnames(styles.NoTounamnetsToDisplayMsg)}>{t("FANTASY.YOU_CANNOT_VIEW_OTHER_USERS_FANTASY_TEAMS")}</div>}

         </div>
         {showPlayerInfoHistoryModal && <PlayerInfoHistoryModal
            data={{
               playerId: selectedPlayerId,
               playerName: selectedPlayerName,
               playerImage: selectedPlayerImage,
               tournamentId: selectedTournamentId
            }}
            handleModalClose={() => {
               setShowPlayerInfoHistoryModal(false);
            }}/>}
         {showPlayerBoostHistoryModal && <PlayerBoostHistoryModal data={modalData} handleModalClose={() => {
            setModalData(null);
            setShowPlayerBoostHistoryModal(false);
         }}/>}
      </div>);
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
   };
};

export default connect(mapStateToProps)(FantasyBotHistory);
